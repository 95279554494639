*{
  margin: 0;
  padding: 0;
} 
* {
  box-sizing: border-box;
}
*:focus{
  outline: none
}
/* html, body, #root, #root>div {
  height: 100%
} */

body {
  background: #1A1A1A;
}

main{
  margin-bottom: 60px;
}
a:hover{
  color: #fff !important;
}
.active {
  color: #FFE016 !important;
  /* background: #FFCA0A; */
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.active svg path {
  stroke: #FFE016;
  color: #FFE016;
}
.bg{
  background-color: #1A1A1A;
}
.filterBlur{
  filter: blur(5px);
}
@import url("./fonts/fonts.css");
h1,h2,h3,h4,h5,h6,p,label,button{
  font-family: open_sans_regular !important;
}
:root{
  --bg-primary:#f8b600;
  --white:#fff;
  --black:black;
  --bg-incorrect:#bd0d0f;
  --correct-bg:#d5f04b;
}
.d-flex{
  display: flex;
}
.flex-row{
  flex-direction: row;
}
.flex-column{
  flex-direction: column;
}
.flex-wrap{
  flex-wrap: wrap;
}
.flex-nowrap{
  flex-wrap: nowrap;
}
.justify-content-center{
  justify-content: center;
}
.align-items-center{
  align-items: center;
}
.border-black{
  border: 1px solid #3d3c3a;
}

.pwa-notification{
  position: absolute !important;
  bottom: 36px;
  z-index: 10;
  display: flex;
  height: 35px;
  left: 0;
  right: 0;
  background: #db7119;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  transition: .3s ease;
}
.opacity1block {
  opacity: 1;
}
/* messages id
#pinMessage{
  display: none;
} */
/* .timer_wrapper{
  position: relative;
} */

.z1{
  z-index: 1;
}
.m-header{
  padding-top: 8px;
  padding-left: 5px;
  padding-right: 10px;
}
/* font-familt */
.outfitRegular{
  font-family: "outfitRegular";
}
.rifficBold{
  font-family: "rifficBold";
}
.outfitBold{
  font-family: "outfitBold";
}
/* font sizes */
.fs-11{
  font-size: 11px;
}
.fs-13{
  font-size: 13px;
}
.fs-15{
  font-size: 15px;
}
.fs-22{
  font-size: 22px;
}
.fs-24{
  font-size: 24px;
}
.fs-20{
  font-size: 20px;
}
.fs-16{
  font-size: 16px;
}

/* font weight */
.fw-600{
  font-weight: 600;
}

/* widths */
.w-20{
  width:20px
}
.w-24{
  width: 24px;
}
.w-100{
  width:100px
}
.w-200{
  width:200px
}
.w-300{
  width:300px
}
.w-400{
  width:400px
}
.w-40px{
  width: 40px;
}
.wmx-350{
  max-width: 350px;
}
.w10rem{
  width: 70% !important;
}
/* heights */
.h-20{
  height: 20px;
}
.h-24{
  height: 24px;
}
.h-50px{
 height: 50px;
}
/* paddings */
.pt-5{
  padding-top: 5px;
}
.pt-10{
  padding-top: 10px;
}
.pt-15{
  padding-top: 15px;
}
.pt-30{
  padding-top: 30px;
}

/* margins */

.mt-3rem{
  margin-top: 3rem;
}
.mt-5{
  margin-top: 5px;
}
.mt-10{
  margin-top: 10px;
}
.mt-15{
  margin-top: 15px;
}
.mt-30{
  margin-top: 30px;
}

.mb-5i{
  margin-bottom: 5px !important;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-15{
  margin-bottom: 15px;
}

.ml-3{
  margin-left: 15px;
}
.letter-spacing-12{
  letter-spacing: 1.2px;
}
.text-black{
  color:black;
}
.text-white{
  color:white;
}
.text-theme{
  color:#FFCA0A !important;
}
.text-left{
  text-align: left;
}
.align-flex-start{
  align-self: flex-start;
}
/* line heights */
.line-height-12{
  line-height:1.2;
}
video {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.form-check-input[type="checkbox"],.form-check-input:checked {
  background-color: transparent !important;
  border-radius: 5px !important;
  border: 2px solid #FFCA0A !important;
}
.form-check-input2[type="checkbox"],.form-check-input2:checked {
  border-radius: 10px !important;
}
.form-check-input:focus{
  box-shadow: none !important;
}
.otpInput{
  border: none;
  background: transparent;
  border-bottom: 1px solid black;
  border-radius: 0 !important;
}
/* html,body{
  background-color: #f8b600;
}
bg-primary{
  background-color: var(--bg-primary);
} */

.editProfileAliceCarouselWrapper > .alice-carousel > .alice-carousel__prev-btn
{
    position: absolute !important;
    top: 30% !important;
    left: -10% !important;
}
.editProfileAliceCarouselWrapper > .alice-carousel > .alice-carousel__prev-btn >.alice-carousel__prev-btn-wrapper
{
    text-align: left;
}
.alice-carousel__prev-btn-item span {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(-45deg);
  border: solid black;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 1px;
  margin-left: 5px;
  margin-top: 2.3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  border-radius: 3px;
}



.editProfileAliceCarouselWrapper > .alice-carousel > .alice-carousel__prev-btn > .alice-carousel__prev-btn-wrapper > .alice-carousel__prev-btn-item > span,.editProfileAliceCarouselWrapper > .alice-carousel > .alice-carousel__next-btn > .alice-carousel__next-btn-wrapper > .alice-carousel__next-btn-item > span{
  width: 20px !important;
  height: 20px !important;
  border: solid white !important;
  border-width: 0 5px 5px 0 !important;
  border-radius: 5px !important;
  margin-top: -15px;
}



.editProfileAliceCarouselWrapper > .alice-carousel > .alice-carousel__next-btn
{
    position: absolute !important;
    right: -18%;
    top: 30%;
}
.editProfileAliceCarouselWrapper > .alice-carousel > .alice-carousel__next-btn >.alice-carousel__next-btn-wrapper
{
    text-align: right;
}
.alice-carousel__next-btn-item span {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(-45deg);
  border: solid black;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 5px;
  margin-left: 1px;
  margin-top: 2.3px;
  border-radius: 3px;
}


.formInput{
  background: var(--bs-body-color);
  border: none;
  border-radius: 25px;
  height: 40px;
  width: 100%;
  color: white;
  text-align: center;
}
.formInputPorfile{
  background: #FF980014;
  border: 1px solid #FFCA0A;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding-left: 15px;
  color: white;
  text-align: left;
}

.userDpBg{
  box-shadow: 0 0 5px 5px #000000b0;
   border-radius: 50%;
    background: #000000b0; 
}
.cursor-pointer{
  cursor: pointer;
}

.letter-spacing-1-2{
  letter-spacing: 1.2px;
} 
.line-height-15{
  line-height: 15px;
}




/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
 .dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.stroke-color-black {
  color: #252525;
}

.centered {
  text-align: center;
  z-index: 1;
  position: relative;
}

.centered h1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.centered p {
  font-size: 18px;
  margin-bottom: 30px;
}