.welcomeDingWrapper{
    position: fixed;
    width: 100%;
    height: 233px;
    max-width: 320px;
    top: 38%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #252525;
    padding: 15px 0;
    margin: 0 auto;
    border-radius: 52px;
    transition: .5s ease;
    opacity: 1;
    padding-top: 0;
  }
.mainDemo{
    position: fixed;
    /* background: #f8b600; */
    background:none;
    z-index: 10;
    top: 0;
    bottom: -170px;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}
.swipeUp{
    -webkit-transform: translateY(-170px);
    -moz-transform: translateY(-170px);
    -o-transform: translateY(-170px);
    -ms-transform: translateY(-170px);
    transform: translateY(-170px);
}
.banner_section_leftMove{
    transform: translateX(-178px) !important;
    left: 0 !important;
}
.banner_section{
    height: 100%;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}
.banner{
    visibility: hidden;
    background: url('../../images/v1/login/wave.png');
    background-repeat: repeat;
    background-size: auto;
    background-size: contain;
    width: 359px;
    height: 400px;
    position: absolute;
    opacity: 0.7;
    left: -2rem;
    right: 0;
    top: 0;
    bottom: 3rem;
    margin: auto;
    background-repeat: no-repeat;
}
.title{
    visibility: hidden;
    align-self: center;
    font-size: 8rem;
    z-index: 1;
    color: var(--white);
    font-family: "rifficBold";
    margin-bottom: 15px;
}
.centerSectionHandler{
    display: flex;
    height: 100%;
}
.bottomSection{
    width: 100%;
    /* background: #1c212a; */
    background: #1c212acc;
    color: white;
    font-family: RifficBold;
    text-align: center;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rightBox{
    /*position: fixed;*/
    height: 100%;
    /* background: #1c212a; */
    background: #1c212acc;
    width: 100%;
    min-width: 100%;
    /*min-width: 230px;*/
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    transform: translateX(100%);
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}
.swipeLeft{
    transform: translateX(-160px) !important;
    /*min-width: 100%;*/
}
.rightBoxShowH2{
    /*width: 100%;*/
    margin-left: -100px;
    visibility: visible !important;
}
.rightSectionH2{
    /*width: 100%;*/
    /* background: #1c212a; */
    /* background: #1c212acc !important; */
    color: white;
    font-family: RifficBold;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    visibility: hidden;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}
.fs2rem{
    font-size: 2rem;
}
.hide{
    display: none;
}
.fadeOut{
    opacity: 0;
    transition: opacity 0.5s ease-out;
}


/* arrow animation */

.arrowAnim90 {
    width: 230px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    /* top: 50%; */
    transform: rotate(90deg);
    margin: 0 auto;
    right: 0;
    left: 0;
    bottom: 250px;
}
.arrowAnim134{
    width: 230px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 4rem;
    transform: rotate(134deg);
    margin: 0 auto;
    right: -1rem;
}
.arrow {
width: 5vw;
height: 5vw;
border: .5vw solid;
border-color: white transparent transparent white;
transform: rotate(-45deg);
}


.arrowSliding {
position: absolute;
-webkit-animation: slide 4s linear infinite; 
        animation: slide 4s linear infinite;
}

.delay1 {
-webkit-animation-delay: 1s; 
    animation-delay: 1s;
}
.delay2 {
-webkit-animation-delay: 2s; 
    animation-delay: 2s;
}
.delay3 {
-webkit-animation-delay: 3s; 
    animation-delay: 3s;
}

@-webkit-keyframes slide {
    0% { opacity:0; transform: translateX(15vw); }  
    20% { opacity:1; transform: translateX(9vw); } 
    80% { opacity:1; transform: translateX(-9vw); }  
100% { opacity:0; transform: translateX(-15vw); } 
}
@keyframes slide {
    0% { opacity:0; transform: translateX(15vw); }  
    20% { opacity:1; transform: translateX(9vw); } 
    80% { opacity:1; transform: translateX(-9vw); }  
100% { opacity:0; transform: translateX(-15vw); } 
}