.reward-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .reward-popup-container {
    background-color: #252525;
    border-radius: 25px;
    padding: 20px;
    text-align: center;
    position: relative;
    max-width: 80%;
    width: 300px;
  }
  
  .lottie-animation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .lottie-placeholder {
    width: 100%;
    height: 300px;
    background-color: #252525;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    color: #888;
  }
  
  .reward-content {
    position: relative;
    z-index: 1;
  }
  
  .congrats-icon {
    width: auto;
    height: 150px;
    margin-bottom: 10px;
  }
  
  .congratulations-text {
    font-size: 24px;
    font-weight: bold;
    color: #FFE016; 
    margin-bottom: 10px;
  }
  
  .reward-text {
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .claim-button {
    background-color: #FFE016; 
    color: #000000;
    border: none;
    border-radius: 25px;
    padding: 10px 28px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .claim-button:hover {
    background-color: #ceb61d;
  }