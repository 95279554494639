header{
    background: url('../../images/v1/redeem/redeem_bg.webp');
    padding: 15px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
    background: #f8b600;
    box-shadow: 0px 2px 7px 4px #0000002b;
}
header div:nth-child(1){
    display: flex;
    justify-content: center;
}
header div:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
header div:nth-child(2) >div:nth-child(1){
    padding: 5px;
}
header div:nth-child(1) img {
    width: 20px;
    height: 20px;
}
header div:nth-child(2) img {
    width: 25px;
    height: 25px;
}
p{
    margin: 0;
    line-height: 1;
    color: white;
}
h1, h2 {
    /* font-family: "rifficBold"; */
    /* font-weight: bold; */
    color: #FFCA0A;
}


