.main {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px 10px;
    background-color: #191919;
    min-height: 100vh;
}

.title {
    color: #FFD700;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.subtitle {
    color: #FFFFFF;
    font-size: 16px;
    margin-bottom: 20px;
}

.insufficientCoins {
    width: 300px;
    padding: 15px 20px;
    display: flex;
    margin: 0 auto;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    height: auto;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #ff6b6b, #f94d6f);
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    position: fixed;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.3s ease;
}

.insufficientCoins p {
    width: 100%;
    text-align: center;
    color: #FFFFFF;
}

.hidden {
    display: none;
}

.show {
    display: flex;
}

.redeemGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.redeemCard {
    background-color: #252525;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.cardImg {
    width: 100%;
    height: 210px;
    object-fit: cover;
    border-radius: 12px;
}

.cardContent {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    flex-grow: 1;
}

.cardTitle {
    color: #FFE016;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.cardText {
    color: #CCCCCC;
    font-size: 12px;
    margin-bottom: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    flex-grow: 1;
}
.redeemButton {
    background-color: #FFD700;
    color: #191919;
    border: none;
    border-radius: 15px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    text-align: center;
}

.redeemButton:hover {
    background-color: #FFC400;
}

.redeemButton:disabled {
    /* opacity: 0.7; */
    cursor: not-allowed;
}

.redeemButton:disabled:hover {
    background-color: #FFD700;
}

.activeButton {
    background-color: #888888 !important;
    color: #FFFFFF !important;
}

.loading, .error {
    color: #FFFFFF;
    text-align: center;
    padding: 20px;
}

.error {
    color: #FF6B6B;
}