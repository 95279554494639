.streak-rewards {
    background-color: #1a1a1a;
    color: white;
    padding: 20px;
    font-family: Arial, sans-serif;
  } 

  .daily-streak, .monthly-streak {
    margin-bottom: 20px;
  }

  .streak-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  .streak-title {
    font-size: 18px;
    font-weight: bold;
  }

  .streak-days {
    font-size: 14px;
    margin-left: auto;
  }

  .reward-message {
    font-size: 14px;
    margin: 0;
  }

  .chest-rewards {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .progress-bar {
    height: 10px;
    background-color: #444;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .progress {
    height: 100%;
    background-color: #ffa500;
  }

  .chests {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }

  .chest {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
  }

  .chest.above {
    padding-bottom: 30px;
  }

  .chest.below {
    padding-top: 30px;
  }

  .chest img {
    width: 50px;
    height: 50px;
  }

  .coin-amount {
    font-size: 12px;
    text-align: center;
    margin: 5px 0;
  }

  :root {
    --color-primary-dark: #FFA500;
    --color-accent: #FF4500;
  }