.avatar-editor-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #1a1a1a;
    font-family: Arial, sans-serif;
    color: white;
}

.avatar-tabs-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: 100%;
    background-color: #1a1a1a;
    padding: 10px 0;
    margin-bottom: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.avatar-tabs-container::-webkit-scrollbar {
    display: none;
}

.avatar-tab {
    padding: 10px 15px;
    margin-right: 15px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
}

.avatar-tab-active::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #FFD700;
}

.avatar-preview-area {
    width: 100%;
    height: 280px;
    background-color: #50D172;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.avatar-preview-content {
    color: white;
    font-size: 24px;
}

.avatar-preview-content svg {
    width: 210px;
}

.avatar-save-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.avatar-save-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.save-error {
    color: #ff0000;
    position: absolute;
    bottom: 40px;
    right: 10px;
    font-size: 12px;
}

.avatar-customize-title {
    margin-bottom: 20px;
}

.avatar-tabs-container {
    display: flex;
    overflow-x: auto;
    margin-bottom: 20px;
    width: 100%;
}

.avatar-tab {
    padding: 10px 15px;
    margin-right: 5px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
    color: white;
}

.avatar-tab-active {
    background-color: transparent;
    color: #FFD700;
}

.avatar-content-area {
    width: 100%;
    padding: 20px;
    background-color: #1a1a1a;
    border-radius: 10px;
}

.avatar-options-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 15px;
    justify-items: center;
}

.avatar-option {
    background: #1a1a1a;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 120px;
    transition: background-color 0.3s ease;
}

.avatar-option:hover {
    background-color: #3a3a3a;
}

.avatar-option svg {
    max-width: 100%;
    max-height: 100%;
    display: block;
    width: 110px;
}

.avatar-option.color-option {
    height: 80px;
    width: 80px;
}

.avatar-option.color-option svg {
    width: 60px;
    height: 60px;
}

.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 300ms linear;
    background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}