.headerLinks {
    text-decoration: none;
    font-size: 15px;
    color: white;
    z-index: 1;
}

.header { 
    background: #191919;
    margin: 0;
    padding: 4px;
    position: relative;
    top: 0;
}

.logoText{
    text-decoration: none;
    font-size: 2rem;
    color: #e5e5e7;
    text-shadow: 0px 3px 0px #db7119, 0 4px 0px #fff, 0 0 30px #fff0, 0 0 40px #ff00de00, 0 0 70px #ff00de00, 0 0 80px #ff00de00, 0 0 100px #ff00de00, 0 0 150px #ff00de00;
    color: #e5e5e7;
    letter-spacing: 0px;
    font-family: rifficBold;
}
.timerWrapper{
    width: 35px;
    max-width: 35px;
    display: flex;
    justify-content: center;
    margin-right: 5px;
    margin-top: 5px;
    position: absolute;
    right: 0;
    top: 70px;
    z-index: 10;
}
.starToken{
    margin-right: 1.2em;
    height: 24px;
    width: 24px;
}
.moveableTimer{
    position: absolute;
    top: 6px;
    margin-right: 1.2em;
    display: flex;
    justify-content: center;
    animation: bounce 5s ease 0s infinite normal forwards;
}
#secondsCounter{
    margin-right: 1.2em;

    font-family: "rifficBold";
    position: absolute;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.14);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}
.dingToken{
    width: 35px;
    height: 35px;
}
/* .borderBottom {
    border-bottom: 2px solid red;
} */
.colorWhite {
    color: white;
}
.ml {
    margin-left: 5px;
}
.accumulationInfo{
    position: relative;
    z-index: 9;
    background: #1c212acc;
    margin-top: 15px;
}
.closeAccumulation{
    position: absolute;
    width: 40px;
    height: 40px;
    right: 5px;
    top: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: start;
}



@keyframes bounce {
	0%,
	100% {
		transform: translateY(-2);
	}

	10%,
	30%,
	50%,
	70% {
		transform: translateY(-8px);
	}

	20%,
	40%,
	60% {
		transform: translateY(-2px);
	}

	80% {
		transform: translateY(-2px);
	}

	90% {
		transform: translateY(-6.4px);
	}
}


.avatarWrapper {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 8px;
    background: beige;
}

.avatarContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center;
    overflow: hidden;
}

.avatarContainer > div {
    width: 264px;
    height: 280px;
}

.avatarContainer svg {
    width: 38px;
}
