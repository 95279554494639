.spin-redeem-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.spin-redeem-dialog {
    background-color: #252525;
    border-radius: 25px;
    padding: 20px;
    position: relative;
    max-width: 90%;
    width: 300px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    color: white;
}

.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.redeem-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
}

.spin-amount-msg {
    font-size: 24px;
    font-weight: bold;
    color: #FFE016; 
    margin-bottom: 10px;
}

.reward-text {
    color: white;
    margin-bottom: 20px;
}

.redeem-button {
    background-color: #FFE016;
    color: #000000;
    border: none;
    border-radius: 25px;
    padding: 10px 28px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
}

.redeem-button:hover {
    background-color: #dfc418;
}