#sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  height: 100vh;
  width: 250px;
  padding: .7em;
  background-color: #252525;
  transition: all 0.3s ease;
  z-index: 999;
  user-select: none;
}
#sidebar.show {
  left: 0;
}
.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: margin-top 0.25s ease-in;
    padding-bottom: 45px;
}
.profileSection{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.imgWrap{
    position: relative;
    display: flex;
    justify-content: center;
}
.imgWrap img{
    width: 70px;
    height: 70px;
}
.editImgBtn{
  width: 25px !important;
  height: 25px !important;
  position: absolute;
  right: 15px;
  background: #414141;
  padding: 5px;
  border-radius: 15px;
}
.phoneWrap p{
    font-size: 12px;
    margin-top: 5px;
}
.coinWrap{
    margin-top: 10px;
}
.coinWrapChild{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.coinText{
    font-family: "rifficBold";
    color: #FFCA0A;
    font-size: 18px;
    margin: 0;
    /* text-shadow: 1px 1px #F8B600; */
    /* text-shadow: 0.5px 0.5px #F8B600; */
}
.performanceSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 80%;

}
.performanceSection h3, .timeSpentSection h3{
    /* font-family: "rifficBold"; */
    color: white;
    /* text-shadow: 1px 1px #F8B600; */
    /* text-shadow: 0.5px 0.5px #F8B600; */
}
.detailsWrapper{
  position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #252525;
    padding: 15px;
    border-radius: 15px;
    width: 100%;
    min-width: 280px;
}
.preferencesSaved {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  user-select: none;
  position: absolute;
  left: 0;
  right: 0;
  background: #6f6d6ea8;
  top: 0;
  bottom: 0;
  border-radius: 15px;
  opacity: 0;
  transition: opacity 1s;
}
.z1{z-index: 2 !important;opacity: 1;}
.z0{z-index: -1 !important;opacity: 0;}

.performanceDetail{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px 0;
}

.performanceValue{
    width: 30px;
}
.MuiChartsAxis-line {
	stroke: white;
	shape-rendering: crispEdges;
	stroke-width: 1;
}


.timeSpentSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-top: 2rem;
}

/* bar graph */


.barcontainer {
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 15px;
    background-color: #252525;
    width: 100%;
    min-width: 280px;
    /* margin: 0 auto; */
    /* height: 170px; */
    height: 250px;
    /* min-height: 25vw;
    max-height: 50vw; */
    /* min-width: 40%;
    max-width: 60%; */
    z-index: 1;
    /* margin-top: 15px; */
}
.barsWrap{
  width: 100%;
  height: 100px;
}
.timeSpentText{
  position: absolute;
  left: 30px;
  top: 17px;
}
.perdayText{
  position: absolute;
  right: 30px;
  top: 17px;
}

.barcontainerheader {
    display: inline;
    position: absolute;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    /* background: #663399; */
    /* border-bottom: 3px solid black; */
    /* font-size: 1.5em; */
    color: white;
    text-align: center;
    z-index: 0;
  }


  .bar {
    position: absolute;
    display: inline-block;
    bottom: 20px;
    background: #C6C6C6;
    width: 10%;
    text-align: center;
    color: white;
    transition: height .3s;
    transition-timing-function: linear;
  }
  
  .barlabel {
    position: absolute;
    /* border-top: 2px solid black;
    background: #888; */
    bottom: -16px;
    width: 100%;
    color: white;
    font-size: 12px;
    /* font-family: "rifficBold"; */
  }
  
  .bar:nth-child(1) {
    left: 8%;
  }
  .bar:nth-child(2) {
    left: 20%;
  }
  
  .bar:nth-child(3) {
    left: 33%;
  }
  
  .bar:nth-child(4) {
    left: 46%;
  }
  
  .bar:nth-child(5) {
    left: 58%;
  }
  
  .bar:nth-child(6) {
    left: 70%;
  }
  
  .bar:nth-child(7) {
    left: 82%;
  }

  .timetextPrompt{
    letter-spacing: 1px;
  }
  /* bar graph */

.buttonSection{
    margin-top: 3rem;
    width: 80%;
}

  .backToVideos{
    color: black;
    letter-spacing: 1.2px;
    background-color: #FFCA0A;
    padding: 10px 42px;
    width: 100%;
    min-width: 46%;
    max-width: 100%;
    border-radius: 25px;
    border: none;
    transition: .1s ease;
    box-shadow: 0px 6px 0px 0px #db8e1d;
  }


  .backToVideos:active{
    transform: translateY(4px);
    box-shadow: none;
  }
.interestsBox{
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
}
.interestDetailsHeader{
    color: #db8e1d;
    font-family: rifficRegular;
}
.interestsDetails{
      font-family: outfitRegular;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      text-align: justify;
}

  @media screen and (min-height: 760px) {
    .main{
        margin-top: 30px
    }
  }
  @media screen and (min-height: 830px) {
    .main{
        margin-top: 45px
    }
  }