.mainContainer {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* padding-top: 3rem; */
    /* height: auto; */
    /* min-height: 80vh; */
    /* height: 85vh; */
    /* padding-top: 1rem; */
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
}
.vidoesContainer {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-content: flex-start;
    /* justify-content: center; */
    /* gap: 1px; */
    /* height: auto; */
    /* max-height: 400px; */
    /* height: 100%; */
    /* min-height: 280px; */
    width: 99vw;
    min-width: 300px;
    /* max-width: 350px; */
    margin-top: 30px;
    /* border: 2px solid #434039; */
    /* background-color: #434039; */
    /* margin: 15px; */
    /* overflow-y: scroll; */
    -ms-overflow-style: none; 
    scrollbar-width: none;
    /* transition: min-height 0.25s ease-in, min-width 0.25s ease-in; */
    overflow-x: scroll;
    padding-left: 15px;
    padding-right: 15px;
}

.vidoesContainer::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}
.basis_50{
    flex-basis: 50%;
    height: 120px;
    text-align: center;
}
.pointer{
    cursor: pointer;
    width: auto;
    padding-left: .5em;
    padding-right: .5em;
    margin: 0 auto;
}
.categoryImage{
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    /* background: #FFCA0A; */
    border-radius: 25px;
    /* padding: 5px; */
}
.categoryText{
    font-size: 12px;
    letter-spacing: 1.2px;
    line-height: 1.2;
}
/* .backToVidesoButton{
    max-width: 300px;
    min-width: 270px;
    width: 300px;
    font-size: 18px;
    font-family: "rifficBold";
    color: white;
    background: #3d3c3b;
    padding: 15px;
    width: 100%;
    border-radius: 25px;
    box-shadow: 0px 6px 0 0 #b57c28;
    border: none;
    transition: .5s ease;
} */
/* @media screen and (min-width: 375px) {
    .vidoesContainer {
        min-width: 95%;
    }
}

@media screen and (min-width: 425px) {
        .vidoesContainer{
            min-width: 380px;
        }
    }
@media screen and (min-width: 451px) {
        .vidoesContainer{
            min-width: 440px;
        }
    }
@media screen and (min-width: 538px) {
    .vidoesContainer {
        min-width: 500px;
    }
} */