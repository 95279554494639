.linkCopied{
    position: fixed;
    z-index: 1;
    margin: 0 auto;
    left: 0;
    right: 0;
    background: #00000057;
    width: 150px;
    font-family: outfitRegular;
    padding: 15px;
    border-radius: 15px;
    text-align: center;
}